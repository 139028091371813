import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between', // Space between the cancel button and title
        alignItems: 'center', // Center items vertically
        padding: '1rem',
        position: 'relative', // Relative position for absolute child if needed
        backgroundColor: theme.palette.neutral[25],
    },
    cancelButton: {
        color: theme.palette.primary[400],
        cursor: 'pointer', // Change cursor to pointer on hover
        zIndex: 1, // Ensure cancel button is on top of other elements
    },
    title: {
        flex: 1, // Allow title to take remaining space
        textAlign: 'center', // Center text horizontally
        left:0,
        right:0,
        position:'absolute',
        color: theme.palette.neutral[700],
    },
}));

export default useStyles;