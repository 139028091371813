import React, { useEffect, useRef } from "react";
import theme from "../../../theme";
import { ThemeProvider } from "@mui/styles";
import { Typography, Box, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import useStyles from "./posterModalStyle";
import { useStoreState, useStoreActions } from "easy-peasy";
import Locale from "../../../locale";
import Card2 from "../../Card/card2";
import CloseIcon from "../../../Assets/Icons/Close.png";
import RestApi from "../../../Utils/Backend";

const PosterModal = ({ open, onClose }) => {
  const locale = Locale();
  const classes = useStyles();
  const { images, sessionId } = useStoreState((state) => ({
    images: state.images,
    sessionId: state.sessionId,
  }));
  const { setPosmData } = useStoreActions((actions) => ({
    setPosmData: actions.setPosterPosmData,
  }));

  const hasMounted = useRef({ planogram: false, posm: false });

  useEffect(() => {
    const getPOSM = async () => {
      const posmData = await RestApi().uploadPosmImage(
        "/backend/get_posm/",
        sessionId,
        "poster",
        0,
        images[3][1][0]
      );
      setPosmData(posmData);
    };

    if (hasMounted.current.posm && images[3][1][0]) {
      getPOSM();
    } else {
      hasMounted.current.posm = true;
    }
  }, [images[3][1][0]]);

  useEffect(() => {
    const getPOSM = async () => {
      const posmData = await RestApi().uploadPosmImage(
        "/backend/get_posm/",
        sessionId,
        "poster",
        1,
        images[3][1][1]
      );
      setPosmData(posmData);
    };

    if (hasMounted.current.posm && images[3][1][1]) {
      getPOSM();
    } else {
      hasMounted.current.posm = true;
    }
  }, [images[3][1][1]]);

  useEffect(() => {
    const getPOSM = async () => {
      const posmData = await RestApi().uploadPosmImage(
        "/backend/get_posm/",
        sessionId,
        "poster",
        2,
        images[3][1][2]
      );
      setPosmData(posmData);
    };

    if (hasMounted.current.posm && images[3][1][2]) {
      getPOSM();
    } else {
      hasMounted.current.posm = true;
    }
  }, [images[3][1][2]]);

  const modalStyle = {
    outline: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "30rem",
    backgroundColor: "white",
    borderRadius: "1rem",
    margin: "0 auto",
    padding: "1.5rem",
    height: "auto",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const optionsStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "0rem",
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div style={modalStyle}>
            <div style={headerStyle}>
              <Typography id="transition-modal-title" variant="h3">
                Poster
              </Typography>
              <img
                alt="close"
                src={CloseIcon}
                width="16px"
                height="16px"
                style={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
            <Typography
              id="transition-modal-description"
              variant="bodySmall"
              className={classes.modalText}
            >
              {locale.PosterModalText}
            </Typography>
            <div style={optionsStyle}>
              <Card2
                number="1"
                title={locale.PosterText}
                capture
                childTitle={locale.PosterText}
                picIndex={[3, 1]}
              />
            </div>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Button
                sx={{ marginTop: "0.875rem !important" }}
                variant="contained"
                className={classes.doneButton}
                onClick={onClose}
              >
                <Typography variant="bodySmall">{locale.Done}</Typography>
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default PosterModal;
