import {
  ThemeProvider,
  Typography,
  Tabs,
  Tab,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import theme from "../../../theme";
import useStyles from "./SingleItemStyles";
import { globalStyles } from "../../../theme";
import ShelfIcon from "../../../Assets/Icons/Shelf.png";
import MoneyIcon from "../../../Assets/Icons/Money.png";
import StarIcon from "../../../Assets/Icons/Star.png";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import RedBullCan from "../../../Assets/Images/redbull.png";
import ArrowDown from "../../../Assets/Icons/ArrowDown.png";
import GoBackHeader from "../../../Components/GoBackHeader/GoBackHeader";
import ContainerTitle from "../../../Components/ContainerTitle/ContainerTitle";
import { useStoreState } from "easy-peasy";
import Locale from "../../../locale";

import DynamicPieChart from "./DynamicPieChart";
import BrandTable from "./BrandTable";

import RedBullLogo from "../../../Assets/Images/BrandLogos/redbull_logo.png";
import HivetownLogo from "../../../Assets/Images/BrandLogos/hivetown_logo.png";
import MonsterLogo from "../../../Assets/Images/BrandLogos/monster_logo.png";
import PowerRootLogo from "../../../Assets/Images/BrandLogos/powerroot_logo.png";
import WarriorLogo from "../../../Assets/Images/BrandLogos/warrior_logo.png";

// const data = [
//   { label: "Red Bull", facing: 6, value: "14.33", color: "#194FF0" },
//   { label: "Power Root", facing: 8, value: "29.46", color: "#08E0DB" },
//   { label: "Warrior", facing: 5, value: "23.47", color: "#02207D" },
//   { label: "Hivetown", facing: 2, value: "20.30", color: "#5D5FEF" },
//   { label: "Monster", facing: 2, value: "12.50", color: "#B872FF" },
// ];

const tempData = [
  {
    label: "Red Bull",
    facing: 0,
    value: 0,
    color: "#FF0000", // Red for Red Bull
    colors: [
      "#FF0000",
      "#FF3333",
      "#FF6666",
      "#FF9999",
      "#FFCCCC",
      "#FF0000",
      "#CC0000",
      "#990000",
      "#660000",
      "#330000",
    ],
    details: [],
  },
  {
    label: "Power Root",
    facing: 0,
    value: 0,
    color: "#00BCD4", // Cyan for Power Root
    colors: [
      "#00BCD4",
      "#33C9DC",
      "#66D5E3",
      "#99E2EB",
      "#CCEEF2",
      "#00BCD4",
      "#00A8C0",
      "#008A9F",
      "#006C7D",
      "#004D5C",
    ],
    details: [],
  },
  {
    label: "Pran",
    facing: 0,
    value: 0,
    color: "#000080", // Navy for Warrior
    colors: [
      "#000080",
      "#333399",
      "#6666B3",
      "#9999CC",
      "#CCCCE6",
      "#000080",
      "#00006E",
      "#00005A",
      "#000046",
      "#000032",
    ],
    details: [],
  },
  {
    label: "Ranger",
    facing: 0,
    value: 0,
    color: "#800080", // Purple for Hivetown
    colors: [
      "#800080",
      "#993399",
      "#B366B3",
      "#CC99CC",
      "#E6CCE6",
      "#800080",
      "#730073",
      "#5C005C",
      "#460046",
      "#320032",
    ],
    details: [],
  },
  {
    label: "Monster",
    facing: 0,
    value: 0,
    color: "#800000", // Maroon for Monster
    colors: [
      "#800000",
      "#993333",
      "#B36666",
      "#CC9999",
      "#E6CCCC",
      "#800000",
      "#730000",
      "#5C0000",
      "#460000",
      "#320000",
    ],
    details: [],
  },
];

const brandLogos = {
  "Red Bull": RedBullLogo,
  "Power Root": PowerRootLogo,
  Warrior: WarriorLogo,
  Hivetown: HivetownLogo,
  Monster: MonsterLogo,
  Pran: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8CitSjfp3d6YzUuMZdLG7nKvc-theEAYmqQ&s",
  Ranger: "https://www.fn.com.my/wp-content/uploads/2018/04/Ranger-Logo_1.png",
};

const tabDataTemp = [
  {
    category: "Red Bull",
    content: [
      {
        product: "RB Bottle 150ml (RBB)",
        price: "N/A",
        change_direction: "+",
        change_percentage: 68,
      },
      {
        product: "RB Gold 250ml (RBG)",
        price: "N/A",
        change_direction: "+",
        change_percentage: 60,
      },
      {
        product: "RB Gold 325ml (RBGL)",
        price: "N/A",
        change_direction: "",
        change_percentage: 0,
      },
      {
        product: "RB Apple Muscat 250ml (RBFA)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 20,
      },
      {
        product: "RB Pomelo 250ml (RBFP)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 12,
      },
      {
        product: "RB Less Sugar 250ml (RBX)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 12,
      },
      {
        product: "RB Plus 250ml (RBP)",
        price: "N/A",
        change_direction: "-",
        change_percentage: 12,
      },
    ],
  },
];

const skuDataTemp = [
  {
    competitor: {
      product: RedBullCan,
      text: "Power Root Extra HoneyTongkat Ali and Ginseng 250ml",
    },
    oppurtunity: { product: RedBullCan, text: "Red Bull Plus 250ml" },
  },
  {
    competitor: {
      product: RedBullCan,
      text: "Hivetown Acacia Honey Sparkling 320ml",
    },
    oppurtunity: { product: RedBullCan, text: "Red Bull Apple Muscat 250ml" },
  },
  {
    competitor: { product: RedBullCan, text: "Warrior Strawberry 320ml" },
    oppurtunity: { product: RedBullCan, text: "Red Bull Gold 325ml" },
  },
];

const TabPanel = ({ children, value, index, theme2 }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={index}
      dir={theme2.direction}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const SingleItem = () => {
  const globalstyles = globalStyles();
  const classes = useStyles();
  const theme2 = useTheme();
  const locale = Locale();
  const [value, setValue] = useState(0);

  const {
    images,
    chillerPlanogramData,
    chillerSkuLabels,
    chillerPosmData,
    opportunityList,
  } = useStoreState((state) => ({
    images: state.images,
    chillerPlanogramData: state.chillerPlanogramData,
    chillerSkuLabels: state.chillerSkuLabels,
    chillerPosmData: state.chillerPosmData,
    opportunityList: state.opportunityList,
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSwipeChange = (index) => {
    setValue(index);
  };

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [data, setData] = useState(tempData);

  const [skuData, setSkuData] = useState([]);
  const [tabData, setTabData] = useState(tabDataTemp);

  useEffect(() => {
    const updateSkuData = () => {
      if (!chillerSkuLabels || !chillerPlanogramData?.class_ids) return;

      // Map to organize products by brand for faster access
      const productsMap = chillerSkuLabels.reduce((map, item) => {
        if (!map[item.brand]) {
          map[item.brand] = [];
        }
        map[item.brand].push(item);
        return map;
      }, {});

      // Loop through the data array and calculate the facing (quantity)
      let totalFacingAllBrands = 0; // Total facing for all brands
      const updatedProducts = tempData
        .map((brandData) => {
          const brandProducts = productsMap[brandData.label] || [];

          // Filter and calculate facing only for products with qty > 0
          const updatedDetails = brandProducts
            .map((product) => {
              const qty = chillerPlanogramData.class_ids.filter(
                (id) => id === product.class_id
              ).length;

              // Include only products with qty > 0
              if (qty > 0) {
                return {
                  name: product.product_name, // Product name
                  facing: qty, // Quantity
                  value: 0, // Placeholder for percentage (to be calculated later)
                  color: brandData.color, // Use brand color
                };
              }
              return null; // Exclude products with qty = 0
            })
            .filter(Boolean) // Remove null values
            .sort((a, b) => b.facing - a.facing); // Sort details by facing in descending order

          // Calculate the total facing for the brand
          const totalFacingForBrand = updatedDetails.reduce(
            (sum, detail) => sum + detail.facing,
            0
          );

          totalFacingAllBrands += totalFacingForBrand; // Add to global total

          // Exclude brands with total facing = 0
          if (totalFacingForBrand === 0) return null;

          return {
            label: brandData.label,
            facing: totalFacingForBrand,
            value: 0, // Placeholder for percentage (to be calculated later)
            details: updatedDetails, // Only products with qty > 0, sorted by facing
            color: brandData.color,
            colors: brandData.colors,
          };
        })
        .filter(Boolean); // Remove null brands

      // Calculate percentages for each brand and their products
      const finalProducts = updatedProducts.map((brand) => {
        const brandValue =
          totalFacingAllBrands > 0
            ? parseFloat(
                ((brand.facing / totalFacingAllBrands) * 100).toFixed(2)
              ) // Brand percentage rounded to 2 decimals
            : 0;

        const updatedDetails = brand.details.map((product) => ({
          ...product,
          value:
            brand.facing > 0
              ? parseFloat(((product.facing / brand.facing) * 100).toFixed(2)) // Product percentage rounded to 2 decimals
              : 0,
        }));

        return {
          ...brand,
          value: brandValue, // Percentage of the brand's facing among all brands
          details: updatedDetails,
        };
      });

      // Create a new sorted array with two levels of sorting
      const sortedProducts = [...finalProducts]
        .map((brand) => ({
          ...brand,
          details: [...brand.details].sort((a, b) => b.facing - a.facing), // Sort products within each brand
        }))
        .sort((a, b) => b.facing - a.facing); // Sort brands by facing

      setData(sortedProducts);
      console.log("Updated Products:", sortedProducts); // Debugging
    };

    updateSkuData();
  }, [chillerPlanogramData, chillerSkuLabels]);

  useEffect(() => {
    console.log("opportunity: ", opportunityList);
    const chillerSkuDetectionClassIds = chillerPlanogramData?.class_ids || [];
    console.log("chillerSkuDetectionClassIds: ", chillerSkuDetectionClassIds);

    if (opportunityList) {
      const competitors = opportunityList?.competitor?.map(
        (competitorId, index) => {
          // Find matching objects in chillerSkuLabels for competitor and opportunity
          const competitorSku = chillerSkuLabels.find(
            (chiller) => chiller._id === competitorId
          );
          console.log("competitorSku: ", competitorSku);
          const opportunitySku = chillerSkuLabels.find(
            (chiller) => chiller._id === opportunityList?.opportunity[index]
          );
          console.log("opportunitySku: ", opportunitySku);

          // Proceed only if both competitorSku and opportunitySku are found
          if (competitorSku && opportunitySku) {
            const isCompetitorDetected = chillerSkuDetectionClassIds.includes(
              competitorSku.class_id
            );
            const isOpportunityDetected = chillerSkuDetectionClassIds.includes(
              opportunitySku.class_id
            );

            // Include in results only if the conditions are met
            if (isCompetitorDetected && !isOpportunityDetected) {
              return {
                competitor: {
                  product: RedBullCan,
                  text: `${
                    competitorSku.brand
                  } ${competitorSku.product_name.replace("RB ", "")}`,
                  imageUrl: competitorSku.image_url,
                },
                oppurtunity: {
                  product: RedBullCan,
                  text: `${
                    opportunitySku.brand
                  } ${opportunitySku.product_name.replace("RB ", "")}`,
                  imageUrl: opportunitySku.image_url,
                },
              };
            }
          }

          // Return undefined if no conditions are met (will be filtered out later)
          return undefined;
        }
      );

      // Filter out undefined entries from the map results
      const filteredCompetitors = competitors?.filter(
        (entry) => entry !== undefined
      );

      console.log("Filtered Competitors:", filteredCompetitors);

      if (filteredCompetitors?.size > 0) {
        setSkuData(competitors);
      }
    }
  }, [chillerPlanogramData, chillerSkuLabels, opportunityList]);

  useEffect(() => {
    const chillerSkuRedBullImages = (chillerSkuLabels || []).filter(
      (image) => image?.brand === "Red Bull"
    );

    const price_list = chillerPosmData?.prices || [];

    // Update the tabDataTemp price field
    const updatedTabData = { ...tabDataTemp[0] }; // Shallow copy to avoid direct mutation
    updatedTabData.content = updatedTabData.content.map((item) => {
      // Find matching image for the product
      const image = chillerSkuRedBullImages.find(
        (img) => img.product_name === item.product
      );

      if (image) {
        const price = price_list[image?.class_id];

        // If price is not -1, update the price
        if (price !== -1) {
          return {
            ...item,
            price, // Update the price field
          };
        }
      }

      // Return the original item if no update is needed
      return item;
    });

    // Optionally log or set state with the updated data
    console.log("Updated Tab Data:", updatedTabData);

    // Example: Update a state
    setTabData([updatedTabData]);
  }, [chillerSkuLabels, chillerPosmData]);

  return (
    <ThemeProvider theme={theme}>
      <GoBackHeader text={locale.EnergyDrink} location={"/analysis"} />
      <div className={globalstyles.mainContainer}>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle
            title={locale.ShareofShelf}
            icon={ShelfIcon}
            images={[images[0][0], images[0][2]]}
          />
          <div className={classes.chartContainer}>
            <DynamicPieChart
              data={data}
              selectedBrand={selectedBrand}
              brandLogos={brandLogos}
            />
          </div>

          <BrandTable
            data={data}
            selectedBrand={selectedBrand}
            setSelectedBrand={setSelectedBrand}
          />
        </div>
        <div className={globalstyles.innerContainer}>
          {/* <ContainerTitle
            title={locale.Pricing}
            icon={MoneyIcon}
            images={[images[0][1], images[0][3]]}
          /> */}
          {/* <Box sx={{ width: "100%", margin: "1rem 0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant={tabData.length > 2 ? "scrollable" : "fullWidth"}
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabData.map((tab, index) => (
                <Tab
                  key={index}
                  sx={{
                    textTransform: "none",
                    "&.Mui-selected": {
                      color: `${theme.palette.primary[400]} !important`, // Selected tab color
                    },
                  }}
                  label={
                    <Typography variant="titleSmall" component="span">
                      {tab.category}
                    </Typography>
                  }
                />
              ))}
            </Tabs> */}
          {/* <SwipeableViews
              axis={theme2.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleSwipeChange}
            >
              {tabData.map((tab, contentIndex) => (
                <TabPanel
                  key={contentIndex}
                  value={value}
                  index={contentIndex}
                  theme2={theme2}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.Product}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.DetectedPrice}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.ActualPrice}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tab.content &&
                          tab.content.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="bodySmall"
                                    style={{
                                      color: row.main_product
                                        ? theme.palette.primary[400]
                                        : theme.palette.neutral[700],
                                    }}
                                  >
                                    {row.product}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <Typography
                                  variant="titleSmall"
                                  style={{
                                    color: row.main_product
                                      ? theme.palette.primary[400]
                                      : row.change_direction === "+"
                                      ? theme.palette.success[300]
                                      : theme.palette.error[300],
                                  }}
                                >
                                  ${row.price}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.change_percentage === 0 ? (
                                  ""
                                ) : (
                                  <Typography
                                    variant="caption"
                                    className={
                                      row.change_direction === "+"
                                        ? classes.tabStatus2
                                        : classes.tabStatus0
                                    }
                                  >
                                    {row.change_direction}
                                    {row.change_percentage}%
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.Product}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.DetectedPrice}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "center",
                              backgroundColor: theme.palette.neutral[200],
                            }}
                          >
                            <Typography
                              variant="bodySmall"
                              style={{
                                color: theme.palette.neutral[400],
                                textAlign: "center",
                              }}
                            >
                              {locale.ActualPrice}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tab.content &&
                          tab.content.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="bodySmall"
                                    style={{
                                      color: theme.palette.neutral[700],
                                    }}
                                  >
                                    {row.product}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <Typography
                                  variant="titleSmall"
                                  style={{
                                    color: theme.palette.primary[400],
                                  }}
                                >
                                  {row.price}
                                </Typography>
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <TextField
                                  size="small"
                                  type="number"
                                  slotProps={{
                                    input: {
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          RM
                                        </InputAdornment>
                                      ),
                                    },
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              ))}
            </SwipeableViews> */}
          {/* </Box> */}
        </div>
        <div className={globalstyles.innerContainer}>
          <ContainerTitle title={locale.SKUDistOpp} icon={StarIcon} />
          <Typography>Will be implemented in phase 2</Typography>
          <div>
            {skuData.map((table, index) => (
              <TableContainer
                key={index}
                className={globalstyles.greyContainer}
              >
                <Table style={{ width: "auto" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <img
                          alt="red bull can"
                          src={RedBullCan}
                          className={classes.sku_image}
                        />
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.neutral[400] }}
                          >
                            {locale.CompFound}
                          </Typography>
                          <Typography variant="titleSmall">
                            {table.competitor.text}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "center",
                          padding: 0,
                        }}
                      >
                        <img
                          src={ArrowDown}
                          alt="arrow down"
                          className={classes.checkIcon}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <img
                          alt="red bull can"
                          src={RedBullCan}
                          className={classes.sku_image}
                        />
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        className={classes.tableCell}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="caption"
                            style={{ color: theme.palette.neutral[400] }}
                          >
                            {locale.Oppurtunity}
                          </Typography>
                          <Typography
                            variant="titleSmall"
                            style={{ color: "#0035D4" }}
                          >
                            {table.oppurtunity.text}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SingleItem;
