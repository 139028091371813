import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    color: theme.palette.neutral[500], // Use theme's neutral color
  },

  statusContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.75rem",
  },

  statusItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "1rem",
  },

  verifiedCircle: {
    backgroundColor: theme.palette.success[200], // Use theme's success color
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    marginRight: "0.25rem",
  },

  unverifiedCircle: {
    backgroundColor: theme.palette.error[200], // Use theme's error color
    width: "0.75rem",
    height: "0.75rem",
    borderRadius: "50%",
    marginRight: "0.25rem",
  },

  /* Table Container Styles */
  tableContainer: {
    maxHeight: "60vh", // Limit table height for better scrolling
    overflow: "auto", // Enable both horizontal and vertical scrolling
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.neutral[50],
  },

  /* Table Styles */
  table: {
    width: "100%",
    borderCollapse: "collapse",
    minWidth: "600px",
  },

  tableHeadCell: {
    fontWeight: "bold",
    padding: "0.75rem 0 !important",
    textAlign: "center !important",
    background: theme.palette.neutral[50],
    color: theme.palette.primary.contrastText,
    top: 0, // Sticky header row
    zIndex: 3, // Higher z-index to stay above other elements
  },

  tableRow: {
    transition: "background-color 0.3s ease",
    color: `${theme.palette.neutral[400]} !important`, // Use theme's neutral color
  },

  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light, // Use theme's primary light color
    },
  },

  tableCellName: {
    padding: "0.75rem 0.5rem !important",
    textAlign: "left !important",
    color: `${theme.palette.neutral[400]} !important`, // Use theme's neutral color
    width: "20%", // Allocate 20% width for the first column
    position: "sticky",
    left: 0, // Sticky to the left
    background: theme.palette.neutral[50],
    zIndex: 2, // Ensure it stays above scrollable columns but below header
  },

  tableHeadFirstCell: {
    fontWeight: "bold",
    textAlign: "left !important",
    background: theme.palette.neutral[50],
    color: theme.palette.primary.contrastText,
    position: "sticky",
    top: 0, // Sticky header row
    left: 0, // Sticky first column in the header
    zIndex: 4, // Highest z-index to stay above other sticky elements
  },

  tableCellStatus: {
    padding: "0.75rem 0.5rem !important",
    textAlign: "center !important",
    color: `${theme.palette.neutral[400]} !important`, // Use theme's neutral color
    background: theme.palette.neutral[50],
    width: "12.5%", // Allocate width for other columns
  },

  /* Responsive Styles */
  "@media (max-width: 768px)": {
    tableContainer: {
      maxHeight: "50vh", // Adjust max height for smaller screens
    },
    tableCellName: {
      width: "30%", // Increase width for smaller screens
    },
    tableCellStatus: {
      width: "15%", // Adjust width for smaller screens
    },
  },
}));

export default useStyles;

