import Good from "../../../Assets/Images/PlanogramTemplates/Good.png"
import Better from "../../../Assets/Images/PlanogramTemplates/Better.png"
import Best from "../../../Assets/Images/PlanogramTemplates/Best.png"

export function PlanogramAnnotation(qualityLevel, roiStatuses) {
    return new Promise((resolve, reject) => {
        
        if (qualityLevel === "FAILED" || !roiStatuses) {
            reject("Invalid data for Planogram Annotation.");
            return null;
        }

        // Predefined ROIs and Images
        const planogramData = {
            GOOD: {
                image: Good,
                rois: [
                    [12, 55, 36, 91],
                    [55, 75, 46, 73],
                    [107, 31, 41, 114],
                    [152, 30, 42, 115],
                    [199, 34, 42, 111],
                    [249, 57, 37, 88],
                    [290, 58, 33, 87],
                    [329, 58, 35, 88],
                ],
            },
            BETTER: {
                image: Better,
                rois: [
                    [8, 65, 42, 68],
                    [54, 65, 44, 68],
                    [102, 65, 44, 69],
                    [150, 25, 41, 107],
                    [195, 28, 40, 105],
                    [241, 28, 40, 104],
                    [285, 28, 42, 104],
                    [332, 28, 41, 104],
                    [20, 155, 35, 83],
                    [62, 156, 35, 82],
                    [102, 157, 34, 80],
                    [141, 157, 34, 80],
                ],
            },
            BEST: {
                image: Best,
                rois: [
                    [16, 70, 45, 69],
                    [63, 70, 45, 69],
                    [110, 69, 40, 69],
                    [151, 33, 41, 105],
                    [194, 33, 43, 105],
                    [239, 33, 41, 105],
                    [282, 33, 41, 104],
                    [327, 33, 44, 103],
                    [25, 155, 41, 101],
                    [72, 154, 40, 102],
                    [119, 155, 41, 102],
                    [168, 157, 41, 100],
                    [217, 176, 33, 81],
                    [257, 177, 33, 78],
                    [296, 178, 31, 77],
                    [333, 178, 32, 78],
                ],
            },
        };

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();

        const selectedPlanogram = planogramData[qualityLevel];
        console.log("Plano Data: ", selectedPlanogram)
        if (!selectedPlanogram) {
            console.log("Invalid quality level: " + qualityLevel);
            return;
        }

        image.onload = () => {
            // Adjust canvas size to the image
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the base image
            ctx.drawImage(image, 0, 0);

            // Draw ROIs
            selectedPlanogram.rois.forEach((roi, index) => {
                const [x, y, w, h] = roi;
                const roiStatus = roiStatuses[index] === 1 ? "good" : "bad";

                // Set stroke style based on ROI status
                ctx.strokeStyle = roiStatus === "good" ? "green" : "red";
                ctx.lineWidth = 2;

                // Draw rectangle
                ctx.strokeRect(x, y, w, h);
            });

            // Convert the canvas to a base64 image URL and resolve the promise
            const finalImageUrl = canvas.toDataURL('image/png');
            resolve(finalImageUrl);
        };

        image.onerror = () => reject("Failed to load the image.");

        // Set the image source directly with the base64 image string
        image.src = selectedPlanogram.image;
    });
}
