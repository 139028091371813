import {
  Backdrop,
  Box,
  Divider,
  Fade,
  Modal,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import theme, { globalStyles } from "../../../theme";
import CloseIcon from "../../../Assets/Icons/Close.png";
import Locale from "../../../locale";
import SampleImage from "../../../Assets/Images/Captured Image.png";

const CaptureGuideModal = ({ open, onClose }) => {
  const globalstyles = globalStyles();
  const locale = Locale();

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            className={globalstyles.modalContainer}
            sx={{
              width: "90%", // Limit the modal width to 90%
              padding: "1rem", // Add padding inside the modal
              margin: "0 auto", // Center the modal horizontally
              borderRadius: "8px", // Optional: Add a border radius for a polished look
              backgroundColor: theme.palette.background.paper, // Maintain modal background
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: "1.25rem",
              }}
            >
              <Typography id="transition-modal-title" variant="h3">
                Capture Guide
              </Typography>
              <img
                alt="close"
                src={CloseIcon}
                width="12px"
                height="12px"
                style={{ padding: "0.5rem", cursor: "pointer" }}
                onClick={onClose}
              />
            </div>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              sx={{
                maxHeight: "80vh", // Limit the height of the modal
                overflowY: "auto", // Enable vertical scrolling
                scrollBehavior: "smooth", // Enable smooth scrolling
              }}
            >
              <Box
                sx={{ width: "100%", margin: "1rem 0 0 0", textAlign: "left" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "1.25rem",
                  }}
                >
                  <Typography id="transition-modal-title" variant="titleSmall">
                    Shelf Capture
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.125rem 0.5rem",
                      borderRadius: "0.25rem",
                      backgroundColor: theme.palette.error[50],
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: theme.palette.error[300] }}
                    >
                      Incorrect
                    </Typography>
                  </div>
                </div>
                <img
                  src={SampleImage}
                  alt="Shelf Capture"
                  style={{
                    maxWidth: "100%", // Image will not exceed the width of its container
                    height: "auto", // Maintain aspect ratio
                  }}
                />
                <Divider style={{ margin: "1.25rem 0" }} />
              </Box>
              <Box sx={{ width: "100%", textAlign: "left" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "1.25rem",
                  }}
                >
                  <Typography id="transition-modal-title" variant="titleSmall">
                    Shelf Capture
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.125rem 0.5rem",
                      borderRadius: "0.25rem",
                      backgroundColor: theme.palette.success[50],
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ color: theme.palette.success[300] }}
                    >
                      Correct
                    </Typography>
                  </div>
                </div>
                <img
                  src={SampleImage}
                  alt="Shelf Capture"
                  style={{
                    maxWidth: "100%", // Image will not exceed the width of its container
                    height: "auto", // Maintain aspect ratio
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default CaptureGuideModal;
