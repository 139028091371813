import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/styles";
import { useStoreState, useStoreActions } from "easy-peasy";

import theme from "../../theme";
import Camera from "../../Assets/Icons/CameraMainPage.png";
import CameraDisabled from "../../Assets/Icons/CameraDisabledMainPage.png";
import useStyles from "./MainPageShopStyle";
import ChillerModal from "../Modals/Chiller/chillerModal";
import PosterModal from "../Modals/Poster/posterModal";

import RestApi from "../../Utils/Backend";

const MainPageShop = () => {
  const classes = useStyles();

  // State for tracking the selected language
  const sessionId = useStoreState((state) => state.sessionId);
  const setSessionId = useStoreActions((actions) => actions.setSessionId);

  const [isChillerModalOpen, setIsChillerModalOpen] = useState(false);
  const [isPosterModalOpen, setIsPosterModalOpen] = useState(false);

  const openChillerModal = () => setIsChillerModalOpen(true);
  const closeChillerModal = () => setIsChillerModalOpen(false);

  const openPosterModal = () => setIsPosterModalOpen(true);
  const closePosterModal = () => setIsPosterModalOpen(false);

  useEffect(() => {
    const fetchSessionId = async () => {
      const session = await RestApi().getSessionId();
      setSessionId(session);
    };

    fetchSessionId();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ChillerModal open={isChillerModalOpen} onClose={closeChillerModal} />
      <PosterModal open={isPosterModalOpen} onClose={closePosterModal} />

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.mainPageShopContainer}>
          <img
            src={Camera}
            alt="Chillers"
            className={classes.chillerImageButton}
            onClick={() => {
              openChillerModal();
            }}
          />
          <img
            src={Camera}
            alt="Posters"
            className={classes.posterImageButton}
            onClick={() => {
              openPosterModal();
            }}
          />
          <img
            src={CameraDisabled}
            alt="Shelves"
            className={classes.shelveImageButton}
            onClick={() => {
              console.log("sclicked");
            }}
          />
          <img
            src={CameraDisabled}
            alt="Blocks"
            className={classes.blockDisImageButton}
            onClick={() => {
              console.log("bclicked");
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default MainPageShop;
