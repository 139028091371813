import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    "@global": {
    body: {
      backgroundColor: theme.palette.neutral[50], // or  if using theme
    },
  },
  title: {
    margin: "1.25rem 0 !important",
  },
  complianceContainer:{
    marginTop:"1.25rem",
    display:"flex",
    flexDirection:"column",
  }
}));

export default useStyles;