import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useState } from "react";

import theme from "../../../theme";
import Locale from "../../../locale";
import useStyles from "./SingleItemStyles";
import { globalStyles } from "../../../theme";

const BrandTable = ({ data, selectedBrand, setSelectedBrand }) => {
  const globalstyles = globalStyles();
  const classes = useStyles();
  const locale = Locale();
  const [openRowIndex, setOpenRowIndex] = useState(null); // Track only one open row

  const toggleRow = (index, brand) => {
    if (openRowIndex === index) {
      // If the clicked row is already open, close it
      setOpenRowIndex(null);
      setSelectedBrand(null);
    } else {
      // Open the new row and close any previously open rows
      setOpenRowIndex(index);
      setSelectedBrand(brand);
    }
  };

  return (
    <TableContainer style={{ width: "100%", overflowX: "auto" }}>
      <Table style={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
            />
            <TableCell
              style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
            >
              <Typography
                variant="bodySmall"
                style={{
                  color: theme.palette.neutral[400],
                  textAlign: "center",
                }}
              >
                {locale.Facing}
              </Typography>
            </TableCell>
            <TableCell
              style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
            >
              <Typography
                variant="bodySmall"
                style={{ color: theme.palette.neutral[400] }}
              >
                {locale.Share}
              </Typography>
            </TableCell>
            <TableCell style={{ borderBottom: "none", flex: 1 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <React.Fragment key={row.label}>
              {/* Main Row */}
              <TableRow>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
                  className={classes.tableCell}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        width: 16,
                        height: 16,
                        backgroundColor: row.color,
                        borderRadius: "25%",
                        marginRight: 8,
                      }}
                    />
                    <Typography
                      variant="bodySmall"
                      style={{ color: theme.palette.primary[400] }}
                    >
                      {row.label}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
                  className={classes.tableCell}
                >
                  <Typography variant="titleSmall">{row.facing}</Typography>
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
                  className={classes.tableCell}
                >
                  <Typography variant="titleSmall">{row.value}%</Typography>
                </TableCell>
                <TableCell
                  style={{ textAlign: "center", borderBottom: "none", flex: 1 }}
                >
                  <IconButton onClick={() => toggleRow(index, row.label)}>
                    {openRowIndex === index ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {/* Collapsible Dropdown Row */}
              <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={4}>
                  <Collapse
                    in={openRowIndex === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box marginBottom={1}>
                      <Table className={globalstyles.greyContainer}>
                        {row.details?.map((detail, idx) => (
                          <TableRow
                            key={idx}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "0.5rem",
                              padding: "0rem",
                            }}
                          >
                            <TableCell
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "none",
                                padding: "0 0.5rem",
                                // flex: 1,
                              }}
                            >
                              <div
                                style={{
                                  width: 10,
                                  height: 10,
                                  backgroundColor: row.colors[idx],
                                  borderRadius: "50%",
                                  marginRight: 8,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                flex: 4,
                                borderBottom: "none",
                                padding: "0.5rem 0.5rem 0.5rem 0",
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{ color: theme.palette.neutral[500] }}
                              >
                                {detail.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                flex: 1,
                                borderBottom: "none",
                                padding: "0.5rem",
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{ color: theme.palette.neutral[500] }}
                              >
                                {detail.facing}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                flex: 1,
                                borderBottom: "none",
                                padding: "0.5rem",
                              }}
                            >
                              <Typography
                                variant="bodySmall"
                                style={{ color: theme.palette.neutral[500] }}
                              >
                                {detail.value}%
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BrandTable;
