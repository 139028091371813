import { ThemeProvider } from "@mui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import theme from "../../theme";
import "./ImageViewer.css"; // Import the CSS file for styling
import GoBackHeader from "../../Components/GoBackHeader/GoBackHeader";
import arrow from "../../Assets/Icons/End Icon.png";
import Locale from "../../locale";
import { Container } from "@mui/material";

import defaultImage from "../../Assets/Images/NoImage.jpg";

const ImageViewer = () => {
  const locale = Locale();
  const location = useLocation();
  const { images } = location.state;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleBack = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleForward = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const getCurrentImage = () => {
    return images[currentIndex] || defaultImage;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="main-container">
        <GoBackHeader text={locale.Captured} location={-1} />
        <div className="image-viewer">
          <div className="nav-buttons">
            <button className="nav-button back-button" onClick={handleBack}>
              <img src={arrow} className="nav-icon" />
            </button>
            <button
              className="nav-button forward-button"
              onClick={handleForward}
            >
              <img src={arrow} className="nav-icon" />
            </button>
          </div>
          <div className="image-container">
            <img
              src={getCurrentImage()}
              alt="Image"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ImageViewer;
