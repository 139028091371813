import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./MainPageTableStyles"; // Ensure the correct path to your useStyles
import Verified from "../../Assets/Icons/Verified.png";
import Unverified from "../../Assets/Icons/Unverified.png";
import Locale from "../../locale";

const MainPageTable = () => {
  const locale = Locale();
  const classes = useStyles();
  const tableData = [
    {
      headers: [" ", "Red Bull"],
    },
    {
      data: [
        {
          name: "Chiller",
          status_1: 1,
          status_2: null,
          status_3: null,
          status_4: 1,
          status_5: null,
          status_6: 1,
          status_7: null,
        },
        {
          name: "Ambient Poster",
          status_1: null,
          status_2: 1,
          status_3: null,
          status_4: null,
          status_5: 1,
          status_6: null,
          status_7: 1,
        },
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainContainer}>
        <div className={classes.statusContainer}>
          <div className={classes.statusItem}>
            <div className={classes.verifiedCircle}></div>
            <Typography variant="caption">{locale.VerifiedText}</Typography>
          </div>
          <div className={classes.statusItem}>
            <div className={classes.unverifiedCircle}></div>
            <Typography variant="caption">{locale.UnverfiedText}</Typography>
          </div>
        </div>
        <TableContainer className={classes.tableContainer}>
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableData[0].headers.map((header, index) => (
                    <TableCell
                      width="50%"
                      key={index}
                      className={
                        index === 0
                          ? classes.tableHeadFirstCell
                          : classes.tableHeadCell
                      }
                      align={index === 0 ? "left" : "center"}
                    >
                      <Typography variant="caption">{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData[1].data.map((row) => (
                  <TableRow key={row.name}>
                    {/* Sticky first column */}
                    <TableCell
                      className={classes.tableCellName}
                      component="th"
                      scope="row"
                    >
                      <Typography variant="caption">{row.name}</Typography>
                    </TableCell>
                    {/* Scrollable columns */}
                    {tableData[0].headers.slice(1).map((header, cellIndex) => {
                      const statusKey = `status_${cellIndex + 1}`;
                      return (
                        <TableCell
                          key={cellIndex}
                          className={classes.tableCellStatus}
                          align="center"
                        >
                          {row[statusKey] != null ? (
                            <img
                              src={row[statusKey] === 1 ? Verified : Unverified}
                              alt="status"
                              style={{ width: "1.6rem", height: "1.6rem" }}
                            />
                          ) : null}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};

export default MainPageTable;
