import { ThemeProvider } from "@emotion/react";
import React from "react";
import theme from "../../theme";
// import { Typography, Divider, Button, MenuItem, Select, InputLabel, FormControl, Box, Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
// import End_Icon from "../../Assets/Icons/End Icon.png";
// import Camera from "../../Assets/Icons/Camera.png";
import Locale from "../../locale";
import useStyles from "./cardStyle";
import CameraModal from "../Modals/Camera/cameraModal";
import { useStoreActions, useStoreState } from "easy-peasy";
import RecaptureButton from "../Buttons/Recapture/Recapture";
import CaptureButton from "../Buttons/Capture/Capture";
import PreviewButton from "../Buttons/Preview/Preview";

const Card = (props) => {
  const images = useStoreState((state) => state.images);
  const isCameraModalOpen = useStoreState((state) =>
    props.picIndex &&
    props.picIndex[0] !== undefined &&
    props.picIndex[1] !== undefined
      ? state.cameraModalState[props.picIndex[0]][props.picIndex[1]]
      : false
  );
  const setIsCameraModalOpen = useStoreActions(
    (actions) => actions.setCameraModalState
  );
  const classes = useStyles();
  const closeCameraModal = () =>
    setIsCameraModalOpen({
      index1: props.picIndex[0],
      index2: props.picIndex[1],
      value: false,
    });
  const locale = Locale();

  // console.log(props.picIndex?.[0], props.picIndex?.[1], props.childTitle);

  return (
    <ThemeProvider theme={theme}>
      <CameraModal
        open={isCameraModalOpen}
        onClose={closeCameraModal}
        modalTitle={props.childTitle}
        picIndex={props.picIndex}
      />
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.circle} />
            <Typography
              sx={{ color: theme.palette.primary[400] }}
              variant="caption"
            >
              {props.number}
            </Typography>
          </div>
          <Typography
            variant="titleLarge"
            sx={{ color: theme.palette.primary[500], textAlign: "left" }}
          >
            {props.title}
          </Typography>
        </div>

        {props.capture &&
        images[props.picIndex?.[0]]?.[props.picIndex?.[1]] == null ? (
          <CaptureButton
            title={locale.CaptureText}
            variant="outlined"
            picIndex={props.picIndex}
          />
        ) : (
          <div className={classes.postCameraButtons}>
            <div style={{ width: "48%" }}>
              <PreviewButton
                title={locale.Preview}
                variant="outlined"
                picIndex={props.picIndex}
              />
            </div>
            <div style={{ width: "48%" }}>
              <RecaptureButton
                title={locale.Recapture}
                variant="filled"
                picIndex={props.picIndex}
              />
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Card;
