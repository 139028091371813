import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import theme from "./../../theme";
import Banner from "../../Components/Top_banner/Banner";
import MainPageTable from "../../Components/MainPageTable/MainPageTable";
import MainPageShop from "../../Components/MainPageShop/MainPageShop";
import HomeAnalyze from "../../Components/Buttons/HomeAnalyze/AnalyzeButton";
import { useStoreActions } from "easy-peasy";

const MainPage = () => {
  const { setYLParams } = useStoreActions((actions) => ({
    setYLParams: actions.setYLParams,
  }));

  const location = useLocation();

  useEffect(() => {
    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);

    // Extract parameters with fallback to an empty string
    const outletCode = queryParams.get("outletCode") || "";
    const outletName = queryParams.get("outletName") || "";
    const staffCode = queryParams.get("staffCode") || "";
    const jobPostingNo = queryParams.get("jobPostingNo") || "";
    const refId = queryParams.get("refId") || "";
    const status = queryParams.get("status") || "";

    // Update the store with parameters
    setYLParams({
      outletCode,
      outletName,
      staffCode,
      jobPostingNo,
      refId,
      status,
    });
  }, [location.search, setYLParams]);

  return (
    <ThemeProvider theme={theme}>
      <Banner />
      <MainPageShop />
      <HomeAnalyze />
      <MainPageTable />
    </ThemeProvider>
  );
};

export default MainPage;
