import React, { useState } from "react";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme";
import { Typography } from "@mui/material";
import HelpIcon from "./../../Assets/Icons/Help.png";
import CloseIcon from "./../../Assets/Icons/Close.png";
import Locale from "../../locale";

const Banner = () => {
  const locale = Locale();
  const [open, setOpen] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-end",
          alignItems: "flex-end",
          flexWrap: "wrap",
          margin: "0 1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "column",
            padding: "0.5rem",
            margin: "1rem 0 -3rem 0",
            color: theme.palette.primary[500],
            backgroundColor: "rgba(0, 0, 0, 0.7)", // 70% opacity
            borderRadius: "0.5rem",
            float: "right",
            backdropFilter: "blur(10px)", // Background blur
          }}
        >
          <img
            src={HelpIcon}
            alt="Info Icon"
            style={{
              width: "1.25rem",
              height: "1.25rem",
              filter: "brightness(0) invert(1)",
            }}
            onClick={() => setOpen(true)}
          />
        </div>
        <div
          style={{
            visibility: open ? "visible" : "hidden",
            outline: "none",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // 70% opacity
            borderRadius: "0.5rem",
            backdropFilter: "blur(10px)", // Background blur
            padding: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            color: theme.palette.neutral[25],
            margin: "4rem 0 -8rem 0",
            zIndex: 1000,
          }}
        >
          <Typography variant="bodySmall">{locale.HeaderTopBanner}</Typography>
          <img
            alt="close"
            src={CloseIcon}
            style={{
              cursor: "pointer",
              width: "0.75rem",
              height: "0.75rem",
              marginLeft: "0.5rem",
            }}
            onClick={() => setOpen(false)}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};
export default Banner;
