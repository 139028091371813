import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.neutral[50], // or  if using theme
    },
  },
  tableCell: {
    padding: "0.75rem 0.75rem 0.625rem 0.75rem !important",
  },
  mainContainer: {
    margin: "0 1rem",
  },
  innerContainer: {
    padding: "1rem",
    backgroundColor: theme.palette.neutral[25],
    borderRadius: "1rem",
    margin: "1.25rem 0",
  },
  checkIcon: {
    width: "1.25rem",
    height: "1.25rem",
  },
  checkIconContainer: {
    margin: "0 0.5rem 0 0",
    padding: "0.375rem",
    backgroundColor: theme.palette.primary[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "25%",
  },
  subtitle: {
    color: theme.palette.primary[500],
  },
  chartContainer: {
    margin: "1rem 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Ensure the container has a height
    width: "100%", // Ensure the container has a width
  },
  header: {
    display: "flex",
    justifyContent: "space-between", // Space between the cancel button and title
    alignItems: "center", // Center items vertically
    padding: "1rem",
    position: "relative", // Relative position for absolute child if needed
    backgroundColor: theme.palette.neutral[25],
  },
  cancelButton: {
    color: theme.palette.primary[400],
    cursor: "pointer", // Change cursor to pointer on hover
    zIndex: 1, // Ensure cancel button is on top of other elements
  },
  title: {
    flex: 1, // Allow title to take remaining space
    textAlign: "center", // Center text horizontally
    left: 0,
    right: 0,
    position: "absolute",
    color: theme.palette.neutral[700],
  },
  tabStatus0: {
    backgroundColor: theme.palette.error[50],
    padding: "0.125rem 0.5rem",
    color: theme.palette.error[300],
    borderRadius: "0.3rem",
  },
  tabStatus1: {
    backgroundColor: "#E3F7F6",
    padding: "0.125rem 0.5rem",
    color: "#058683",
    borderRadius: "0.3rem",
  },
  tabStatus2: {
    backgroundColor: theme.palette.success[50],
    padding: "0.125rem 0.5rem",
    color: theme.palette.success[300],
    borderRadius: "0.3rem",
  },
  sku_image: {
    height: "3.75rem",
    width: "3.75rem",
  },
}));

export default useStyles;
