import { ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme, { globalStyles } from "../../../theme";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Button } from "@mui/material";
import Locale from "../../../locale";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import LoadingIcon from "../../../Assets/Animations/Loading.json";
import RestApi from "../../../Utils/Backend";

const HomeAnalyze = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const locale = Locale();
  const images = useStoreState((state) => state.images);
  const globalstyles = globalStyles();

  const { chillerPlanogramData, chillerPosmData, posterPosmData } =
    useStoreState((state) => ({
      chillerPlanogramData: state.chillerPlanogramData,
      chillerPosmData: state.chillerPosmData,
      posterPosmData: state.posterPosmData,
    }));

  const {
    setChillerSkuLabels,
    setChillerPosmLabels,
    setPosterPosmLabels,
    setOpportunityList,
  } = useStoreActions((actions) => ({
    setChillerSkuLabels: actions.setChillerSkuLabels,
    setChillerPosmLabels: actions.setChillerPosmLabels,
    setPosterPosmLabels: actions.setPosterPosmLabels,
    setOpportunityList: actions.setOpportunityList,
  }));

  const analyzeImages = async () => {
    setLoading(true);
    const chillerSkuLabels = await RestApi().getSkuLabels("chiller");
    const chillerPosmLabels = await RestApi().getPosmLabels("chiller");
    const posterPosmLabels = await RestApi().getPosmLabels("poster");
    const opportunityList = await RestApi().getOpportunityList();
    setChillerSkuLabels(chillerSkuLabels);
    setChillerPosmLabels(chillerPosmLabels);
    setPosterPosmLabels(posterPosmLabels);
    setOpportunityList(opportunityList);
  };

  useEffect(() => {
    if (loading) {
      if (
        (images[0][0] != null &&
          images[0][1] != null &&
          Object.keys(chillerPlanogramData).length > 0 &&
          Object.keys(chillerPosmData).length > 0) ||
        (images[3][1] != null && Object.keys(posterPosmData).length > 0)
      ) {
        setLoading(false);
        navigate("/analysis");
      }
    }
  }, [
    chillerPlanogramData,
    chillerPosmData,
    posterPosmData,
    loading,
    navigate,
  ]);
  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: "1rem" }}>
        <Button
          disabled={
            !(
              (images[0][0] != null && images[0][1] != null) ||
              images[3][1] != null
            )
          }
          variant="contained"
          onClick={() => {
            analyzeImages();
          }}
          className={
            loading
              ? globalstyles.analyzeButtonLoading
              : globalstyles.analyzeButton
          }
          style={{
            display: "flex",
            alignItems: "stretch",
            padding: "0.5rem 1rem",
          }}
        >
          <Typography variant="titleSmall">
            {loading ? locale.AnalyzingButton : locale.AnalyzeButton}
          </Typography>
          {loading && (
            <Lottie
              animationData={LoadingIcon}
              loop={true}
              style={{
                width: "1.2rem",
                marginLeft: "0.5rem",
                marginBottom: "-0.25rem",
              }}
            />
          )}
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default HomeAnalyze;
