import { Button, ThemeProvider, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../../../theme";
import useStyles from "./PreviewStyles";
import Camera from "../../../Assets/Icons/Eye.png";
import { useStoreActions } from "easy-peasy";

const PreviewButton = ({ title, variant, picIndex }) => {
  const classes = useStyles();
  const setIsCameraModalOpen = useStoreActions(
    (actions) => actions.setCameraModalState
  );
  return (
    <ThemeProvider theme={theme}>
      <Button
        className={classes.postCameraButtons}
        variant={variant}
        onClick={() => setIsCameraModalOpen({ index1: picIndex[0],index2:picIndex[1], value: true })}
        style={{
          backgroundColor:
            variant === "outlined" ? "white" : theme.palette.primary[400],
          borderRadius: "0.5rem",
          color: variant === "outlined" ? theme.palette.primary[400] : "white",
        }}
      >
        <img
          alt="Recapture"
          src={Camera}
          className={classes.recaptureIcon}
          style={{
            filter: variant !== "outlined" ? "brightness(0) invert(1)" : "none",
          }}
        />
        <Typography variant="titleSmall">{title}</Typography>
      </Button>
    </ThemeProvider>
  );
};

export default PreviewButton;
