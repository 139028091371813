import { Button, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import useStyles from "./RecaptureStyles";
import Camera from "../../../Assets/Icons/Camera.png";
import { useStoreActions } from "easy-peasy";

const RecaptureButton = ({ title, variant, picIndex }) => {
  const classes = useStyles();
  const { setCameraModalState: setIsCameraModalOpen, removeImage } =
    useStoreActions((actions) => ({
      setCameraModalState: actions.setCameraModalState,
      removeImage: actions.removeImage,
    }));

  const handleClick = () => {
    removeImage({
      index: [picIndex[0]],
      index2: [picIndex[1]],
    });
    setIsCameraModalOpen({
      index1: picIndex[0],
      index2: picIndex[1],
      value: true,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Button
        className={classes.postCameraButtons}
        variant={variant}
        onClick={handleClick}
        style={{
          backgroundColor:
            variant === "outlined" ? "white" : theme.palette.primary[400],
          borderRadius: "0.5rem",
          color: variant === "outlined" ? theme.palette.primary[400] : "white",
        }}
      >
        <img
          alt="Recapture"
          src={Camera}
          className={classes.recaptureIcon}
          style={{
            filter: variant !== "outlined" ? "brightness(0) invert(1)" : "none",
          }}
        />
        <Typography variant="titleSmall">{title}</Typography>
      </Button>
    </ThemeProvider>
  );
};

export default RecaptureButton;
