// RestAPI.jsx

const RestApi = () => {
  const getSkuLabels = async (stock) => {
    try {
      const requestBody = {
        labels: [], // Adjust this as needed
        stock: stock,
      };

      const response = await fetch("/backend/get_sku_labels/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch SKU labels: ${response.statusText}`);
      }

      const jsonResponse = await response.json();
      // Ensure we have a valid images array
      const images = jsonResponse.images || [];
      if (!Array.isArray(images)) {
        throw new Error("'images' is not an array");
      }
      return images;
    } catch (error) {
      console.error("Error in getSkuLabels:", error.message || error);
      return []; // Return an empty array in case of error
    }
  };

  const getPosmLabels = async (stock) => {
    try {
      const requestBody = {
        labels: [],
        stock: stock,
      };

      const response = await fetch("/backend/get_posm_labels/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch POSM labels: ${response.statusText}");
      }

      const jsonResponse = await response.json();

      const images = jsonResponse.images || [];
      if (!Array.isArray(images)) {
        throw new Error("'images' is not an array");
      }
      return images;
    } catch (error) {
      console.error("Error POSM :", error);
      return null;
    }
  };

  const getOpportunityList = async () => {
    try {
      const requestBody = {};

      const response = await fetch("/backend/get_opportunity_list/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json", // Explicitly request JSON response
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch Opportunity List: ${response.statusText}`
        );
      }

      const jsonResponse = await response.json();

      // Validate and return the list
      if (jsonResponse && typeof jsonResponse.list === "object") {
        return jsonResponse.list;
      } else {
        console.warn("Unexpected response format:", jsonResponse);
        return {};
      }
    } catch (error) {
      console.error("Error fetching Opportunity List:", error);
      return {}; // Return an empty object to avoid returning null
    }
  };

  // Function to upload a single file as JSON
  const uploadImage = async (url, sessionId, stock, base64Image) => {
    try {
      // Create JSON payload
      const payload = {
        sessionId: sessionId,
        stock: stock,
        image: base64Image, // base64-encoded image as a string
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      console.log("Image upload response:", jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.error("Error Image upload:", error);
      return {};
    }
  };

  // Function to upload a single file as JSON
  const uploadPosmImage = async (url, sessionId, stock, index, base64Image) => {
    try {
      // Create JSON payload
      const payload = {
        sessionId: sessionId,
        stock: stock,
        index: index,
        image: base64Image, // base64-encoded image as a string
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      console.log("Image upload response:", jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.error("Error Image upload:", error);
      return {};
    }
  };

  // Function to upload multiple files
  const uploadMultipleFiles = async (url, files) => {
    try {
      const formData = new FormData();

      // Append each file to the FormData object
      Array.from(files).forEach((file, index) => {
        formData.append(`file_${index}`, file);
      });

      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const jsonResponse = await response.json();
      console.log("Multiple files upload response:", jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.error("Error in multiple files upload:", error);
      return null;
    }
  };

  // Function to get session ID using a GET request
  const getSessionId = async () => {
    try {
      const response = await fetch("/backend/session/", {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Failed to retrieve session ID");
      }

      const jsonResponse = await response.json();
      console.log("Session ID:", jsonResponse);
      return jsonResponse.sessionId; // Assuming the response has a sessionId field
    } catch (error) {
      console.error("Error retrieving session ID:", error);
      return null;
    }
  };

  // Function to Report Session
  const reportSession = async (sessionId) => {
    try {
      // Create JSON payload
      const payload = {
        sessionId: sessionId,
      };

      const response = await fetch("/backend/report_session/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      return jsonResponse.status === "success";
    } catch (error) {
      console.error("Error Reporting:", error);
      return false; // Return false in case of an error
    }
  };

  // Function to Send Results
  const sendResults = async (sessionId, yl_results, prices) => {
    try {
      // Create JSON payload
      const payload = {
        sessionId: sessionId,
        yl_results: yl_results,
        prices: prices,
      };

      const response = await fetch("/backend/send_result/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const jsonResponse = await response.json();
      return jsonResponse.status === "success";
    } catch (error) {
      console.error("Error Data Sending:", error);
      return false; // Return false in case of an error
    }
  };

  return {
    uploadImage,
    uploadPosmImage,
    uploadMultipleFiles,
    getSessionId,
    getSkuLabels,
    getPosmLabels,
    reportSession,
    sendResults,
    getOpportunityList,
  };
};

export default RestApi;
