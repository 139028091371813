import { createStore, action } from "easy-peasy";

const MainStore = createStore({
  // Initial state
  locale: "EN",
  images: [
    [null, [null, null, null], null, [null, null, null], null, null],
    [null, null, null, null, null, null],
    [null, null, null, null, null, null],
    [null, [null, null, null], null, [null, null, null], null, null],
  ],
  ylParams: {},
  actual_prices: [0, 0, 0, 0, 0, 0, 0],
  sessionId: "",
  chillerPlanogramData: {},
  chillerPosmData: {},
  posterPosmData: {},
  chillerSkuLabels: [],
  chillerPosmLabels: [],
  posterPosmLabels: [],
  opportunityList: {},
  analyzedData: {
    Chiller: {
      SKU: {},
      Shelving: {},
      Planogram: {},
      POSM: {},
    },
    Poster: {
      SKU: {},
      Shelving: {},
      POSM: {},
    },
  },
  cameraModalState: [
    [false, [false, false, false], false, false, false, false],
    [false, false, false, false, false, false],
    [false, false, false, false, false, false],
    [false, [false, false, false], false, false, false, false],
  ],

  // Actions
  setLocale: action((state, payload) => {
    state.locale = payload;
  }),

  addImage: action((state, payload) => {
    const { index, index2, value } = payload;
    if (!Array.isArray(state.images[index][index2])) {
      state.images[index][index2] = null;
    }
    state.images[index][index2] = value;
  }),

  addPosmImage: action((state, payload) => {
    const { index, index2, index3, value } = payload;
    if (!Array.isArray(state.images[index][index2][index3])) {
      state.images[index][index2][index3] = null;
    }
    state.images[index][index2][index3] = value;
  }),

  removeImage: action((state, payload) => {
    const { index, index2 } = payload;
    if (state.images[index] && state.images[index][index2]) {
      state.images[index][index2] = null;
    }
  }),

  removePosmImage: action((state, payload) => {
    const { index, index2, index3 } = payload;
    if (state.images[index] && state.images[index][index2][index3]) {
      state.images[index][index2][index3] = null;
    }
  }),

  addImages: action((state, payload) => {
    state.images = [...state.images, ...payload];
  }),

  setYLParams: action((state, payload) => {
    state.ylParams = payload;
  }),

  setPrice: action((state, payload) => {
    const { index, value } = payload;
    if (index < state.actual_prices.length) {
      state.actual_prices[index] = value;
    }
  }),

  setSessionId: action((state, payload) => {
    state.sessionId = payload;
  }),

  setChillerPlanogramData: action((state, payload) => {
    state.chillerPlanogramData = payload;
  }),

  setChillerPosmData: action((state, payload) => {
    state.chillerPosmData = payload;
  }),

  setPosterPosmData: action((state, payload) => {
    state.posterPosmData = payload;
  }),

  setChillerSkuLabels: action((state, payload) => {
    state.chillerSkuLabels = payload;
  }),

  setChillerPosmLabels: action((state, payload) => {
    state.chillerPosmLabels = payload;
  }),

  setPosterPosmLabels: action((state, payload) => {
    state.posterPosmLabels = payload;
  }),

  setOpportunityList: action((state, payload) => {
    state.opportunityList = payload;
  }),

  setAnalyzedData: action((state, payload) => {
    const { stock, type, value } = payload;
    state.analyzedData[stock][type] = value;
  }),

  setCameraModalState: action((state, payload) => {
    const { index1, index2, value } = payload;
    if (
      index1 < state.cameraModalState.length &&
      index2 < state.cameraModalState[index1].length
    ) {
      state.cameraModalState[index1][index2] = value;
    }
  }),

  setPosmCameraModalState: action((state, payload) => {
    const { index1, index2, index3, value } = payload;
    if (
      index1 < state.cameraModalState.length &&
      index2 < state.cameraModalState[index1].length &&
      index3 < state.cameraModalState[index1][index2].length
    ) {
      state.cameraModalState[index1][index2][index3] = value;
    }
  }),

  clearCameraModalState: action((state, index1, index2) => {
    if (
      index1 < state.cameraModalState.length &&
      index2 < state.cameraModalState[index1].length
    ) {
      state.cameraModalState[index1][index2] = false;
    }
  }),

  clearPosmCameraModalState: action((state, index1, index2, index3) => {
    if (
      index1 < state.cameraModalState.length &&
      index2 < state.cameraModalState[index1].length &&
      index3 < state.cameraModalState[index1][index2].length
    ) {
      state.cameraModalState[index1][index2][index3] = false;
    }
  }),

  clearAllCameraModalStates: action((state) => {
    state.cameraModalState = [];
  }),

  // Reset All Action
  resetAll: action((state) => {
    state.images = [
      [null, [null, null, null], null, [null, null, null], null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, [null, null, null], null, [null, null, null], null, null],
    ];
    state.ylParams = {};
    state.actual_prices = [0, 0, 0, 0, 0, 0, 0];
    state.sessionId = "";
    state.chillerPlanogramData = {};
    state.chillerPosmData = {};
    state.posterPosmData = {};
    state.chillerSkuLabels = [];
    state.chillerPosmLabels = [];
    state.posterPosmLabels = [];
    state.opportunityList = {};
    state.analyzedData = {
      Chiller: {
        SKU: {},
        Shelving: {},
        POSM: {},
      },
      Poster: {
        SKU: {},
        Shelving: {},
        POSM: {},
      },
    };
    state.cameraModalState = [
      [false, [false, false, false], false, false, false, false],
      [false, false, false, false, false, false],
      [false, false, false, false, false, false],
      [false, [false, false, false], false, false, false, false],
    ];
  }),

  // Reset Image Action
  resetImages: action((state) => {
    state.images = [
      [null, [null, null, null], null, [null, null, null], null, null],
      [null, null, null, null, null, null],
      [null, null, null, null, null, null],
      [null, [null, null, null], null, [null, null, null], null, null],
    ];
  }),
});

export default MainStore;
