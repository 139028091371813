// export function generateAnnotatedImage(data, base64Image) {
//     return new Promise((resolve, reject) => {
//         if (!data || !base64Image) {
//             reject("Invalid data or image.");
//             return;
//         }

//         // Create new filtered data by omitting ROIs with class_ids > 7
//         const filteredData = {
//             ...data,
//             rois: data.rois.filter((_, index) => data.class_ids[index] <= 7),
//             class_ids: data.class_ids.filter(id => id <= 7)
//         };

//         const canvas = document.createElement('canvas');
//         const ctx = canvas.getContext('2d');
//         const image = new Image();

//         // Ensure matched_rois is an array
//         const matchedROIs = Array.isArray(filteredData.matched_rois) ? filteredData.matched_rois : [];

//         // Determine ROIs to draw based on the presence of matched ROIs
//         const unmatchedROIs = matchedROIs.length > 0
//             ? filteredData.rois.filter(roi =>
//                 !matchedROIs.some(matchedROI =>
//                     JSON.stringify(matchedROI) === JSON.stringify(roi)
//                 )
//             )
//             : filteredData.rois; // If no matched ROIs, treat all as unmatched

//         image.onload = () => {
//             // Set canvas dimensions to match image
//             canvas.width = image.width;
//             canvas.height = image.height;
//             console.log(image.width, image.height)

//             // Draw the base image
//             ctx.drawImage(image, 0, 0);

//             // Draw unmatched ROIs in red with transparency
//             ctx.fillStyle = 'rgba(255, 0, 0, 0.5)'; // Red with transparency
//             unmatchedROIs.forEach(roi => {
//                 const [x1, y1, x2, y2] = roi;
//                 ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
//             });

//             // Draw matched ROIs in green with transparency, if any
//             if (matchedROIs.length > 0) {
//                 ctx.fillStyle = 'rgba(0, 255, 0, 0.5)'; // Green with transparency
//                 matchedROIs.forEach(roi => {
//                     const [x1, y1, x2, y2] = roi;
//                     ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
//                 });
//             }

//             // Convert the canvas to a base64 image URL and resolve the promise
//             const finalImageUrl = canvas.toDataURL('image/png');
//             resolve(finalImageUrl);
//         };

//         image.onerror = () => reject("Failed to load the image.");

//         // Set the image source directly with the base64 image string
//         image.src = base64Image;
//     });
// }

export function generateAnnotatedImage(data, base64Image) {
    return new Promise((resolve, reject) => {
        console.log("Generating Image")
        if (!data || !base64Image) {
            reject("Invalid data or image.");
            return null;
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const image = new Image();

        image.onload = () => {
            // Set canvas dimensions to match image
            canvas.width = image.width;
            canvas.height = image.height;

            // Draw the base image
            ctx.drawImage(image, 0, 0);

            // Draw all ROIs
            data.rois.forEach((roi, index) => {
                const [x1, y1, x2, y2] = roi;
                const id = data.class_ids[index];

                // Draw rectangle (stroke only)
                ctx.strokeStyle = 'red'; // Red outline for all ROIs
                ctx.lineWidth = 2;
                ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

                // Add the id inside the rectangle at the top-left corner
                ctx.font = '50px Arial';
                ctx.fillStyle = 'red';
                ctx.fillText(`${id}`, x1 + 10, y1 + 50); // Adjusted for padding
            });

            // Convert the canvas to a base64 image URL and resolve the promise
            const finalImageUrl = canvas.toDataURL('image/png');
            resolve(finalImageUrl);
        };

        image.onerror = () => reject("Failed to load the image.");

        // Set the image source directly with the base64 image string
        image.src = base64Image;
    });
}
