import { ThemeProvider } from "@emotion/react";
import React from "react";
import theme from "../../theme";
// import { Typography, Divider, Button, MenuItem, Select, InputLabel, FormControl, Box, Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
// import End_Icon from "../../Assets/Icons/End Icon.png";
// import Camera from "../../Assets/Icons/Camera.png";
import Locale from "../../locale";
import useStyles from "./cardStyle";
import CameraModal2 from "../Modals/Camera/cameraModal2";
import { useStoreActions, useStoreState } from "easy-peasy";
import CaptureButton2 from "../Buttons/Capture/Capture2";
import PreviewButton2 from "../Buttons/Preview/Preview2";

const Card2 = (props) => {
  const classes = useStyles();
  const locale = Locale();

  const images = useStoreState((state) => state.images);

  // const isCameraModalOpen1 = useStoreState((state) =>
  //   props.picIndex &&
  //   props.picIndex[0] !== undefined &&
  //   props.picIndex[1] !== undefined
  //     ? state.cameraModalState[props.picIndex[0]][props.picIndex[1]][0]
  //     : false
  // );
  // const closeCameraModal1 = () => {
  //   useStoreActions((actions) =>
  //     actions.setPosmCameraModalState({
  //       index1: props.picIndex[0],
  //       index2: props.picIndex[1],
  //       index3: 0,
  //       value: false,
  //     })
  //   );
  // };

  // const isCameraModalOpen2 = useStoreState((state) =>
  //   props.picIndex &&
  //   props.picIndex[0] !== undefined &&
  //   props.picIndex[1] !== undefined
  //     ? state.cameraModalState[props.picIndex[0]][props.picIndex[1]][1]
  //     : false
  // );
  // const closeCameraModal2 = () => {
  //   useStoreActions((actions) =>
  //     actions.setPosmCameraModalState({
  //       index1: props.picIndex[0],
  //       index2: props.picIndex[1],
  //       index3: 1,
  //       value: false,
  //     })
  //   );
  // };

  // const isCameraModalOpen3 = useStoreState((state) =>
  //   props.picIndex &&
  //   props.picIndex[0] !== undefined &&
  //   props.picIndex[1] !== undefined
  //     ? state.cameraModalState[props.picIndex[0]][props.picIndex[1]][2]
  //     : false
  // );
  // const closeCameraModal3 = () => {
  //   useStoreActions((actions) =>
  //     actions.setPosmCameraModalState({
  //       index1: props.picIndex[0],
  //       index2: props.picIndex[1],
  //       index3: 2,
  //       value: false,
  //     })
  //   );
  // };

  const useCameraModalState = (picIndex, index) => {
    const isCameraModalOpen = useStoreState((state) =>
      picIndex && picIndex[0] !== undefined && picIndex[1] !== undefined
        ? state.cameraModalState[picIndex[0]][picIndex[1]][index]
        : false
    );

    const closeCameraModal = useStoreActions(
      (actions) => (value) =>
        actions.setPosmCameraModalState({
          index1: picIndex[0],
          index2: picIndex[1],
          index3: index,
          value: false,
        })
    );

    return { isCameraModalOpen, closeCameraModal };
  };

  const {
    isCameraModalOpen: isCameraModalOpen1,
    closeCameraModal: closeCameraModal1,
  } = useCameraModalState(props.picIndex, 0);
  const {
    isCameraModalOpen: isCameraModalOpen2,
    closeCameraModal: closeCameraModal2,
  } = useCameraModalState(props.picIndex, 1);
  const {
    isCameraModalOpen: isCameraModalOpen3,
    closeCameraModal: closeCameraModal3,
  } = useCameraModalState(props.picIndex, 2);

  // console.log(props.picIndex?.[0], props.picIndex?.[1], props.childTitle);

  return (
    <ThemeProvider theme={theme}>
      <CameraModal2
        open={isCameraModalOpen1}
        onClose={closeCameraModal1}
        modalTitle={props.childTitle}
        picIndex={[props.picIndex[0], props.picIndex[1], [0]]}
      />
      <CameraModal2
        open={isCameraModalOpen2}
        onClose={closeCameraModal2}
        modalTitle={props.childTitle}
        picIndex={[props.picIndex[0], props.picIndex[1], [1]]}
      />
      <CameraModal2
        open={isCameraModalOpen3}
        onClose={closeCameraModal3}
        modalTitle={props.childTitle}
        picIndex={[props.picIndex[0], props.picIndex[1], [2]]}
      />
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.circle} />
            <Typography
              sx={{ color: theme.palette.primary[400] }}
              variant="caption"
            >
              {props.number}
            </Typography>
          </div>
          <Typography
            variant="titleLarge"
            sx={{ color: theme.palette.primary[500], textAlign: "left" }}
          >
            {props.title}
          </Typography>
        </div>

        <div
          className={classes.postCameraButtons}
          style={{
            display: "flex",
            justifyContent: "space-between", // Spread buttons evenly
            alignItems: "center", // Align buttons vertically in the center
            gap: "0.5rem", // Add some spacing between buttons
          }}
        >
          {Array.from({ length: 3 }).map((_, index) =>
            props.capture &&
            images[props.picIndex?.[0]]?.[props.picIndex?.[1]]?.[index] ==
              null ? (
              <CaptureButton2
                key={`capture-${index}`}
                title={locale.CaptureText}
                variant="outlined"
                style={{ flex: "1 1 0", minWidth: "10%" }} // Force buttons to fit in the row
                picIndex={[props.picIndex[0], props.picIndex[1], [index]]}
              />
            ) : (
              <PreviewButton2
                key={`preview-${index}`}
                title={locale.Preview}
                variant="outlined"
                style={{ flex: "1 1 0", minWidth: "10%" }} // Force buttons to fit in the row
                picIndex={[props.picIndex[0], props.picIndex[1], [index]]}
              />
            )
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Card2;
